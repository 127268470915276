<script>
import {value} from "lodash/seq";

export default{
  props: ["data"],
  data(){
    return{
      value: ""
    }
  },
  methods: {
    sendValue(){
      let obj = {}
      obj[this.data.key] = this.value
      this.$emit("sendValue", obj)
    }
  }
}
</script>

<template>
  <Fragment>
    <label>{{data.ti}}</label>
    <el-select v-model="value" :placeholder="`请输入${data.ti}`" @change="sendValue">
      <el-option
        v-for="item in data.options"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
  </Fragment>
</template>

<style scoped lang="less">
  label{
    min-width: 80px;
    text-align: right;
  }
  .el-select{
    margin-left: 15px;
    width: 112%
  }
  .el-option{

  }
</style>
