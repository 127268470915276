<script>
import {value} from "lodash/seq";

export default {
  props: ["data"],
  data(){
    return {
      value: ""
    }
  },
  methods: {
    sendValue(){
      let obj = {}
      obj[this.data.key] = this.value.join("/")
      this.$emit("sendValue", obj)
    }
  }
}
</script>

<template>
  <fragment>
    <label>{{data.ti}}</label>
    <el-cascader
      v-model="value"
      :options="data.options"
      @change="sendValue">
    </el-cascader>
  </fragment>

</template>

<style scoped lang="less">
label{
  min-width: 80px;
  text-align: right;
}
.el-cascader{
  margin-left: 15px;
  width: 100%;
  max-width: 300px;
}
</style>
