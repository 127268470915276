<script>
import SearchInput from "@/view/search/child/SearchInput.vue";
import SearchSelect from "@/view/search/child/SearchSelect.vue";
import SearchDatePicker from "@/view/search/child/SearchDatePicker.vue";
import SearchCascader from "@/view/search/child/SearchCascader.vue";
export default {
  props: ["data"],
  data(){
    return{
      conditions: {},
      seniorState: false
    }
  },
  computed:{
    CalculationStyle(){
      return this.seniorState? `height: ${this.data.list.length * 60 - 20}px` : null
    }
  },
  methods:{
    /**
     *  筛选组件：根据arr的每一项type返回不同的组件
     * @param arr
     * @return {{props: [string]}}
     */
    selectComponent(arr){
      switch (arr){
        case "input":
          return SearchInput
        case "select":
          return SearchSelect
        case "datePicker":
          return SearchDatePicker
        case "Cascader":
          return SearchCascader
      }
    },
    openAdvancedQuery(){
      this.seniorState = !this.seniorState
    },
    /**
     * 接收子组件提交的数据, 添加到条件项里
     * @param data
     */
    sendValue(data){
      Object.assign(this.conditions, data)
    },
    /**
     * 搜索提交按钮
     */
    searchBtn(){
      this.$emit("searchCallBack", this.conditions, "search")
    },
    /**
     * 重置所有条件，并重新搜索
     */
    resettingSearchBtn(){
      this.conditions = {}
      this.$children.forEach(obj =>{
        obj.value = ""
      })
      this.searchBtn()
    }
  },
  render(h, context) {
    return h("div",{
      class: {
        "search_content": true,
      },
      style: this.CalculationStyle
    }, [
      h("div", {
        class: "condition"
      }, this.data.list.map(options =>{
        return h("div",{
          class: "options"
        }, options.map(item =>{
          return h(this.selectComponent(item.type), {
            props: {
              data: item,
            },
            on: {
              sendValue: this.sendValue
            }
          })
        }))
      })),
      h("div", {
        class: "control"
      }, [
        h("button",{
          class: {
            "advanced_query": true,
            "senior_state": this.seniorState,
            "disable": !this.data.senior
          },
          on: {
            click: this.openAdvancedQuery
          }
        }, "高级查询"),
        h("button",{
          class: "query",
          on: {
            click: this.searchBtn
          }
        }, "查询"),
        h("button",{
          class: "resetting",
          on: {
            click: this.resettingSearchBtn
          }
        }, "重置")
      ])
    ])
  }
}
</script>


<style scoped lang="less">
.search_content{
  display: flex;
  overflow: hidden;
  height: 40px;
  transition: height .5s;
}
.condition{
  width: 1000px;
}
.options{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.options:last-child{
  margin-bottom: 0
}
.control{
  min-width: 250px;
  margin-top: 3px;
  flex: 1;
}
button{
  float: right;
  color: white;
  background-color: #358F6C;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  cursor: pointer;
}
.query:hover{
  background-color: rgba(53, 143, 108, 0.7);
}
.query:active{
  background-color: #358F6C;
}
.resetting{
  background-color: rgba(252, 3, 32, 0.7);
  margin-right: 15px;
}
.resetting:hover{
  background-color: rgba(252, 3, 32, 0.5);
}
.query:active{
  background-color: rgb(252, 3, 32);
}
.advanced_query{
  background-color: transparent;
  color: #358F6C;
}
.advanced_query::after{
  content: "";
  display: inline-block;
  width: 16px;
  height: 10px;
  margin-left: 5px;
  background-image: url("../../assets/images/drop-down.png");
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 0.5s;
  transform: rotate(0);
}
.senior_state::after{
  transform: rotate(180deg);
}
.disable{
  display: none;
}
</style>
