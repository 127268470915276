<script>
export default{
  props: ["data"],
  data(){
    return{
      value: ""
    }
  },
  methods: {
    sendValue(){
      let obj = {}
      obj[this.data.key] = this.value
      this.$emit("sendValue", obj)
    }
  }
}
</script>

<template>
  <Fragment>
    <label>{{data.ti}}</label>
    <input type="text" v-model="value" :placeholder="`请输入${data.ti}`" :class="data.class" @change="sendValue"/>
  </Fragment>
</template>

<style scoped lang="less">
  label{
    min-width: 80px;
    text-align: right;
  }
  input{
    -webkit-appearance: none;
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
    font-size: 14px;
  }
  input::placeholder{
    color: rgba(0, 0, 0, 0.3);
  }
  input:focus{
    border: 1px solid #358F6C;
  }
  input{
    margin-left: 15px;
  }
</style>
