<script>
export default {
  props: ["data"],
  data(){
    return{
      value: ""
    }
  },
  methods: {
    sendValue(){
      let obj = {}
      obj[this.data.key[0]] = this.value[0]
      obj[this.data.key[1]] = this.value[1]
      this.$emit("sendValue", obj)
    }
  }
}
</script>

<template>
  <fragment>
    <label :style="{minWidth: data.labelWidth + 'px'}">{{data.ti}}</label>
    <el-date-picker
      v-model="value"
      type="daterange"
      value-format="yyyy-MM-dd"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="sendValue">
    </el-date-picker>
  </fragment>
</template>

<style scoped lang="less">
label{
  min-width: 80px;
  text-align: right;
}
.el-date-editor{
  margin-left: 15px;
  width: 100%;
}
.el-range-editor.is-active{
  border: 1px solid #358F6C;
}
</style>
